<template>
  <section class="section h-100 is-flex is-flex-direction-column is-justify-content-space-between">
    <div v-if="license">
      <h2 class="title">
        CyRadar Advanced Threat Detection
        <div class="subtitle">
          {{ $t('version', [version]) }} <span v-if="license.features">- {{ formatTraffic(license.features.bandwidth_limit * Math.pow(1000, 3)) }}</span>
        </div>
      </h2>

      <div class="columns">
        <div class="column">
          <box>
            <div slot="header">
              {{ $t('license') }}
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('name') }}</div>
              <div>{{ license.name }}</div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('plan') }}</div>
              <div>{{ license.plan }}</div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>Key</div>
              <div>{{ license.api_key }}</div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('expiration') }}</div>
              <div>{{ license.expired_at | dmyhs }}</div>
            </div>
          </box>
        </div>
        <div class="column">
          <box>
            <div slot="header">
              {{ $t('systemOverview') }}
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('route.sensors') }}</div>
              <div>{{ systemStats.sensors }}</div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('bandwidth') }}</div>
              <div>
                {{ bandwidthHuman(systemStats.bandwidth_avg * 8) }} (avg) / {{ bandwidthHuman(systemStats.bandwidth_peak * 8) }} (peak)
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('traffic') }}</div>
              <div>{{ formatTraffic(systemStats.traffic) }}</div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>{{ $t('client') }}</div>
              <div>{{ systemStats.clients }}</div>
            </div>
          </box>
        </div>
      </div>
    </div>
    <footer class="is-size-65">
      <h3 class="is-size-5 mb-2">
        Contact
      </h3>
      <p class="columns mb-0">
        <strong class="is-uppercase column is-1">Telephone</strong><span class="is-inline-flex is-align-items-center">(+84) 24 7300 6066</span>
      </p>
      <p class="columns mb-0">
        <strong class="is-uppercase column is-1">Email</strong><span class="is-inline-flex is-align-items-center">contact@cyradar.com</span>
      </p>
      <p class="columns mb-0">
        <strong class="is-uppercase column is-1">Address</strong><span class="is-inline-flex is-align-items-center">9th Floor, FPT Tower, 10 Pham Van Bach street, Cau Giay District, Ha Noi</span>
      </p>
    </footer>
  </section>
</template>

<script>
import { bandwidthHuman, formatTraffic } from '@/utils'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['version', 'license', 'systemStats'])
  },
  methods: {
    bandwidthHuman,
    formatTraffic
  }
}
</script>
<style lang="scss">
footer {
  .column {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
</style>
